<template>
  <b-card no-body class="card-profile" alt="Image placeholder" img-top>
    <b-row class="justify-content-center">
      <b-col lg="3" class="order-lg-2">
        <div class="card-profile-image">
          <a href="javascript:void(0)">
            <b-img v-if="user.me && user.me.avatar" :src="user.me.avatar" rounded="circle" />
            <b-img v-else src="/favicon.png" rounded="circle" />
          </a>
        </div>
      </b-col>
    </b-row>

    <b-card-header class="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4 mt-7">
      <div class="d-flex justify-content-between">
        <b-button class="btn btn-sm btn-info mr-4" v-b-modal.avatarModal>写真変更</b-button>
        <!-- <a href="#" class="btn btn-sm btn-default float-right">Message</a> -->
      </div>
    </b-card-header>

    <b-card-body class="pt-0" v-if="user.me">
      <!-- <b-row>
        <b-col >
          <div class="card-profile-stats d-flex justify-content-center mt-md-5">
            <div>
              <span class="heading">22</span>
              <span class="description">Friends</span>
            </div>
            <div>
              <span class="heading">10</span>
              <span class="description">Photos</span>
            </div>
            <div>
              <span class="heading">89</span>
              <span class="description">Comments</span>
            </div>
          </div>
        </b-col>
      </b-row> -->
      <div class="text-center">
        <h5 class="h3">
          {{ user.me.full_name }}<span class="font-weight-light">, {{ age }}</span>
        </h5>
        <div class="h5 font-weight-300">
          <i class="ni location_pin mr-2"></i>{{ user.me.username }}
        </div>
        <div class="h5 font-weight-300">
          <i class="ni location_pin mr-2"></i>{{ user.me.address1 }}
        </div>
        <div class="h5 mt-4">
          <i class="ni business_briefcase-24 mr-2"></i>{{ full_org_name }}
        </div>
        <div class="h5 font-weight-300" v-for="(position, key) in positions_name" :key="key">
          {{ position }}
        </div>
        <hr class="my-4">
        <div class="h5 font-weight-300 text-gray">
          <i class="ni education_hat mr-2"></i>前回ログイン：{{ last_login }}
        </div>

      </div>
    </b-card-body>
    <b-modal id="avatarModal" @ok="handleOk">
      <vue-croppie
        ref="croppieRef"
        :enableOrientation="false"
        :enableResize="false"
        :boundary="{ width: 300, height: 300 }"
      >
      </vue-croppie>
      <input type="file" @change="croppie"/>
    </b-modal>
  </b-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Toast from '../../components/Toast';
import { ajax, common, config, constant } from '../../utils';

export default {
  data: function() {
    return {
      croppieImage: '',
      cropped: null,
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapGetters('account', ['full_org_name', 'positions_name']),
    age: function() {
      if (this.user && this.user.me && this.user.me.birthday) {
        return common.getAge(this.user.me.birthday);
      } else {
        return null;
      }
    },
    last_login: function() {
      if (this.user && this.user.me && this.user.me.last_login) {
        return common.formatDate(this.user.me.last_login, 'YYYY-MM-DD hh:mm')
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions('account', ['setAvatar']),
    croppie (e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };

      reader.readAsDataURL(files[0]);
    },
    crop() {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      let options = {
        type: 'base64',
        size: { width: 300, height: 300 },
        format: 'png'
      };
      this.$refs.croppieRef.result(options, output => {
          this.cropped = this.croppieImage = output;
          const vm = this;
          ajax.fetchPut(config.api.account.avatar, {blob: this.croppieImage}).then((json) => {
            Toast.success(constant.SUCCESS.SAVED);
            vm.$bvModal.hide("avatarModal");
            vm.setAvatar(json.url);
          }).catch(errors => {
            Toast.errorFromApi(errors);
          });
        }
      );
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.crop();
    },
  }
}
</script>
<style></style>
