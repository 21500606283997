<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="4" class="order-xl-2 mb-5">
          <user-card></user-card>
        </b-col>
        <b-col xl="8" class="order-xl-1">
          <b-card no-body>
            <b-card-header class="border-1">
              <h3 class="mb-0">{{ user && user.me ? user.me.full_name : null }}の個人情報</h3>
            </b-card-header>
            <b-card-body>
              <div>
                <b-row>
                  <b-col>
                    <h6 class="heading-small text-muted">基本情報</h6>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <b-button size="sm" variant="link" v-b-modal.basic-info>
                      <b-icon icon="pencil-square" variant="primary"></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple v-if="employee">
                  <b-tbody>
                    <b-tr>
                      <b-th>社員番号</b-th>
                      <b-td>{{ employee.code }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>名前</b-th>
                      <b-td>{{ employee.full_name }}&nbsp;{{ employee.full_kana ? `（${employee.full_kana}）` : '' }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>生年月日</b-th>
                      <b-td>{{ employee.birthday }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>国籍・地域</b-th>
                      <b-td>{{ get_country(employee.country) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>性別</b-th>
                      <b-td>{{ get_gender(employee.gender) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>婚姻状況</b-th>
                      <b-td>{{ get_marriage(employee.marriage) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>会社メールアドレス</b-th>
                      <b-td>{{ employee.email }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>個人メールアドレス</b-th>
                      <b-td>{{ employee.private_email }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>携帯番号</b-th>
                      <b-td>{{ employee.phone }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>住所</b-th>
                      <b-td>
                        {{ employee.post_code ? `〒${employee.post_code}` : '' }}
                        &nbsp;{{ employee.address1 }}{{ employee.address2 }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>最寄駅</b-th>
                      <b-td>{{ employee.nearest_station }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-row class="mt-4">
                  <b-col>
                    <h6 class="heading-small text-muted">パスポート</h6>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <b-button size="sm" variant="link" v-b-modal.passport-modal>
                      <b-icon icon="pencil-square" variant="primary"></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple>
                  <b-tbody>
                    <b-tr>
                      <b-th>パスポート番号</b-th>
                      <b-td>{{ employee.passport_number }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>パスポート有効期限</b-th>
                      <b-td>{{ employee.passport_expired_date }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>パスポートの写し</b-th>
                      <b-td>
                        <a
                          href="javascript:void(0)" 
                          v-if="employee.passport_image" 
                          @click="show_image(employee.passport_image)"
                        >
                          {{employee.passport_image_name}}
                        </a>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-row class="mt-4">
                  <b-col>
                    <h6 class="heading-small text-muted">個人番号</h6>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <b-button size="sm" variant="link" v-b-modal.personal-number-modal>
                      <b-icon icon="pencil-square" variant="primary"></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple>
                  <b-tbody>
                    <b-tr>
                      <b-th>個人番号</b-th>
                      <b-td>{{ employee.personal_number }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>個人番号の写し（表面）</b-th>
                      <b-td>
                        <a
                          href="javascript:void(0)"
                          v-if="employee.personal_number_image"
                          @click="show_image(employee.personal_number_image)"
                        >
                          {{employee.personal_number_image_name}}
                        </a>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>個人番号の写し（裏面）</b-th>
                      <b-td>
                        <a
                          href="javascript:void(0)"
                          v-if="employee.personal_number_image_back"
                          @click="show_image(employee.personal_number_image_back)"
                        >
                          {{employee.personal_number_image_back_name}}
                        </a>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-row class="mt-4">
                  <b-col>
                    <h6 class="heading-small text-muted">年金</h6>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <b-button size="sm" variant="link" v-b-modal.basic-pension-modal>
                      <b-icon icon="pencil-square" variant="primary"></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple>
                  <b-tbody>
                    <b-tr>
                      <b-th>基礎年金番号</b-th>
                      <b-td>{{ employee.basic_pension_no }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>基礎年金番号の写し</b-th>
                      <b-td>
                        <a
                          href="javascript:void(0)"
                          v-if="employee.basic_pension_image"
                          @click="show_image(employee.basic_pension_image)"
                        >
                          {{employee.basic_pension_image_name}}
                        </a>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-row class="mt-4">
                  <b-col>
                    <h6 class="heading-small text-muted">保険</h6>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <b-button size="sm" variant="link" v-b-modal.insurance-modal>
                      <b-icon icon="pencil-square" variant="primary"></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple>
                  <b-tbody>
                    <b-tr>
                      <b-th>雇用保険証被保険者番号</b-th>
                      <b-td>{{ employee.employment_insurance_no }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>健康保険記号</b-th>
                      <b-td>{{ employee.healthy_insurance_no }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>健康保険番号</b-th>
                      <b-td>{{ employee.healthy_insurance_sub_no }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>健康保険の写し</b-th>
                      <b-td>
                        <a
                          href="javascript:void(0)"
                          v-if="employee.healthy_insurance_image"
                          @click="show_image(employee.healthy_insurance_image)"
                        >
                          {{employee.healthy_insurance_image_name}}
                        </a>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-row class="mt-4">
                  <b-col>
                    <h6 class="heading-small text-muted">在留カード情報</h6>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <b-button size="sm" variant="link" v-b-modal.residence-modal>
                      <b-icon icon="pencil-square" variant="primary"></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple>
                  <b-tbody>
                    <b-tr>
                      <b-th>在留カード番号</b-th>
                      <b-td>{{ residence.residence_no }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>在留資格種類</b-th>
                      <b-td>{{ get_residence_type(residence.residence_type) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>在留カード期限</b-th>
                      <b-td>{{ residence.expired_date }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>在留カードの写し</b-th>
                      <b-td>
                        <a
                          href="javascript:void(0)"
                          v-if="residence.residence_image"
                          @click="show_image(residence.residence_image)"
                        >
                          {{residence.residence_image_name}}
                        </a>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>在留カードの写し（裏面）</b-th>
                      <b-td>
                        <a
                          href="javascript:void(0)"
                          v-if="residence.residence_image_back"
                          @click="show_image(residence.residence_image_back)"
                        >
                          {{residence.residence_image_back_name}}
                        </a>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <!-- 基本情報変更のダイアログ -->
      <b-modal
        id="basic-info"
        scrollable
        title="基本情報変更"
        size="lg"
        @show="handleShowBasicForm"
        @ok="handleBasicInfoOk"
      >
        <base-form
          :schema="basic_info_schema"
          :layout="basic_info_layout"
          :value="employee_for_change"
          ref="basci_info_form"
        >
        </base-form>
      </b-modal>
      <!-- パスポート情報変更のダイアログ -->
      <b-modal
        id="passport-modal"
        scrollable
        title="パスポート情報変更"
        size="md"
        @show="handleShowBasicForm"
        @ok="handlePassportFormOk"
      >
        <base-form
          :schema="passport_schema"
          :value="employee_for_change"
          ref="passport_form"
        >
        </base-form>
      </b-modal>
      <!-- 個人番号情報変更のダイアログ -->
      <b-modal
        id="personal-number-modal"
        scrollable
        title="個人番号情報変更"
        size="md"
        @show="handleShowBasicForm"
        @ok="handlePersonalNoFormOk"
      >
        <base-form
          :schema="personal_number_schema"
          :value="employee_for_change"
          ref="personal_number_form"
        >
        </base-form>
      </b-modal>
      <!-- 年金情報変更のダイアログ -->
      <b-modal
        id="basic-pension-modal"
        scrollable
        title="年金情報変更"
        size="md"
        @show="handleShowBasicForm"
        @ok="handleBasicPensionFormOk"
      >
        <base-form
          :schema="basic_pension_schema"
          :value="employee_for_change"
          ref="basic_pension_form"
        >
        </base-form>
      </b-modal>
      <!-- 保険のダイアログ -->
      <b-modal
        id="insurance-modal"
        scrollable
        title="保険に関する情報変更"
        size="md"
        @show="handleShowBasicForm"
        @ok="handleInsuranceFormOk"
      >
        <base-form
          :schema="insurance_schema"
          :value="employee_for_change"
          ref="insurance_form"
        >
        </base-form>
      </b-modal>
      <!-- 在留カード情報 -->
      <b-modal
        id="residence-modal"
        scrollable
        title="在留カード情報変更"
        size="md"
        @show="handleShowResidenceForm"
        @ok="handleResidenceFormOk"
      >
        <base-form
          :schema="residence_schema"
          :value="residence_for_change"
          ref="residence_form"
        >
        </base-form>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { ajax, common, config, choice } from '../../utils';
import UserCard from './UserCard';
import { 
  profile_basic_info_layout,
  profile_basic_info_schema,
  profile_passport_schema,
  profile_personal_number_schema,
  profile_basic_pension_schema,
  profile_insurance_schema,
  profile_residence_schema,
} from './schema';
import Toast from '../../components/Toast';
import { api } from '../common';

export default {
  components: {
    UserCard,
  },
  data() {
    return {
      employee: {},
      employee_for_change: {},
      residence: {},
      residence_for_change: {},
      basic_info_schema: profile_basic_info_schema,
      basic_info_layout: profile_basic_info_layout,
      passport_schema: profile_passport_schema,
      personal_number_schema: profile_personal_number_schema,
      basic_pension_schema: profile_basic_pension_schema,
      insurance_schema: profile_insurance_schema,
      residence_schema: profile_residence_schema,
    }
  },
  computed: {
    ...mapState('account', ['user']),
  },
  watch: {
    user: function() {
      this.get_employee_info();
      this.get_residence_status();
    }
  },
  created() {
    document.title = "プロファイル";
    this.get_employee_info();
    this.get_residence_status();
  },
  methods: {
    get_employee_info: function() {
      if (!this.user || !this.user.me) {
        return;
      }
      const vm = this;
      ajax.fetchGet(common.formatStr(config.api.employee.employee_detail, this.user.me.employee)).then(data => {
        vm.employee = data;
      });
    },
    get_residence_status() {
      if (!this.user || !this.user.me) {
        return;
      }
      const vm = this;
      ajax.fetchGet(common.formatStr(config.api.employee.residence_status, this.user.me.employee)).then(data => {
        vm.residence = data;
      });
    },
    get_gender: function(gender) {
      return common.getChoiceText(gender, choice.gender);
    },
    get_country: function(country) {
      return common.getChoiceText(country, choice.country);
    },
    get_marriage: function(marriage) {
      return common.getChoiceText(marriage, choice.marriage);
    },
    get_residence_type: function(residence_type) {
      return common.getChoiceText(residence_type, choice.residence);
    },
    show_image: function(uuid) {
      api.viewAttachment(uuid, this);
    },
    handleShowBasicForm() {
      this.employee_for_change = common.clone(this.employee);
    },
    handleShowResidenceForm() {
      this.residence_for_change = common.clone(this.residence);
    },
    async handleBasicInfoOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValid = await this.$refs.basci_info_form.validate()
      if (isValid) {
        this.handleFormOk('basic-info', {
          'last_name': this.employee_for_change.last_name,
          'first_name': this.employee_for_change.first_name,
          'last_name_ja': this.employee_for_change.last_name_ja,
          'first_name_ja': this.employee_for_change.first_name_ja,
          'last_name_en': this.employee_for_change.last_name_en,
          'first_name_en': this.employee_for_change.first_name_en,
          'birthday': this.employee_for_change.birthday,
          'country': this.employee_for_change.country,
          'gender': this.employee_for_change.gender,
          'marriage': this.employee_for_change.marriage,
          'email': this.employee_for_change.email,
          'private_email': this.employee_for_change.private_email,
          'phone': this.employee_for_change.phone,
          'post_code': this.employee_for_change.post_code,
          'address1': this.employee_for_change.address1,
          'address2': this.employee_for_change.address2,
          'nearest_station': this.employee_for_change.nearest_station,
        });
      }
    },
    async handlePassportFormOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValid = await this.$refs.passport_form.validate();
      if (isValid) {
        this.handleFormOk('passport-modal', {
          'passport_number': this.employee_for_change.passport_number,
          'passport_expired_date': this.employee_for_change.passport_expired_date,
          'passport_image': this.employee_for_change.passport_image,
        });
      }
    },
    async handlePersonalNoFormOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValid = await this.$refs.personal_number_form.validate();
      if (isValid) {
        this.handleFormOk('personal-number-modal', {
          'personal_number': this.employee_for_change.personal_number,
          'personal_number_image': this.employee_for_change.personal_number_image,
          'personal_number_image_back': this.employee_for_change.personal_number_image_back,
        });
      }
    },
    async handleBasicPensionFormOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValid = await this.$refs.basic_pension_form.validate();
      if (isValid) {
        this.handleFormOk('basic-pension-modal', {
          'basic_pension_no': this.employee_for_change.basic_pension_no,
          'basic_pension_image': this.employee_for_change.basic_pension_image,
        });
      }
    },
    async handleInsuranceFormOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValid = await this.$refs.insurance_form.validate();
      if (isValid) {
        this.handleFormOk('insurance-modal', {
          'employment_insurance_no': this.employee_for_change.employment_insurance_no,
          'healthy_insurance_no': this.employee_for_change.healthy_insurance_no,
          'healthy_insurance_sub_no': this.employee_for_change.healthy_insurance_sub_no,
          'healthy_insurance_image': this.employee_for_change.healthy_insurance_image,
        });
      }
    },
    async handleResidenceFormOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValid = await this.$refs.residence_form.validate();
      if (isValid) {
        const vm = this;
        ajax.fetchPost(common.formatStr(config.api.employee.residence_status, vm.user.me.employee), vm.residence_for_change).then(data => {
          vm.residence = data;
          vm.$bvModal.hide('residence-modal');
        }).catch(errors => {
          Toast.errorFromApi(errors);
        });
      }
    },
    handleFormOk(modal_name, data) {
      const url = common.formatStr(config.api.employee.employee_detail, this.user.me.employee);
      const vm = this;
      ajax.fetchPut(url, data).then(data => {
        vm.employee = data;
        vm.$bvModal.hide(modal_name);
      }).catch(errors => {
        Toast.errorFromApi(errors);
      });
    },
  }
}
</script>